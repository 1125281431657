<template>
    <h1 class="mb-4 text-4xl font-bold leading-none tracking-tight text-custom_organization-900">
        {{$t("Users")}}
        <span v-if="userId == 'NEW_USER'" class="text-2xl text-custom_organization-900">- {{$t("New user")}}</span>
        <span v-if="userId != 'NEW_USER'" class="text-2xl text-custom_organization-900">- {{ fixedFirstname + ' ' + fixedLastname}}</span>
    </h1>

    <div class="flex justify-end">
        <button class="flex gap-2 rounded-full py-2 px-3 min-w-[100px] bg-zinc-300 hover:bg-zinc-400 mb-5"
          @click="goBack()">
          <i class="gg-arrow-left"></i>{{$t("Back to list")}}
        </button>
    </div>

    <form class="grid grid-cols-10 items-center gap-3">
        <label class="col-span-1">{{$t("Login")}}</label>
        <input type="text" v-model="username" :disabled="pending"
            class="col-span-9 block bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-custom_organization-500 focus:ring-custom_organization-500 focus:border-custom_organization-500 w-60 p-2.5"/>
        <label class="col-span-1">{{$t("Password")}}</label>
            <input type="password" v-model="password" :disabled="pending || (userId != 'NEW_USER' && !isAdmin)"
            class="col-span-9 block bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-custom_organization-500 focus:ring-custom_organization-500 focus:border-custom_organization-500 w-60 p-2.5 disabled:bg-zinc-200"/>
        <label class="col-span-1">{{$t("Firstname")}}</label>
            <input type="text" v-model="firstname" :disabled="pending"
            class="col-span-9 block bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-custom_organization-500 focus:ring-custom_organization-500 focus:border-custom_organization-500 w-60 p-2.5"/>
        <label class="col-span-1">{{$t("Lastname")}}</label>
            <input type="text" v-model="lastname" :disabled="pending"
            class="col-span-9 block bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-custom_organization-500 focus:ring-custom_organization-500 focus:border-custom_organization-500 w-60 p-2.5"/>
        <label class="col-span-1">{{$t("Email")}}</label>
            <input type="text" v-model="email" :disabled="pending"
            class="col-span-9 block bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-custom_organization-500 focus:ring-custom_organization-500 focus:border-custom_organization-500 w-60 p-2.5"/>
    </form>

    <div class="flex justify-end">
        <button class="flex gap-2 rounded-full py-2 px-3 min-w-[100px] bg-green-400 hover:bg-green-500 mb-5 disabled:bg-green-300"
            v-if="userId == 'NEW_USER'" @click="create()" :disabled="pending">
                <i class="gg-add-r"></i>{{$t("Create user")}}
        </button>
        <button class="flex gap-2 rounded-full py-2 px-3 min-w-[100px] bg-green-400 hover:bg-green-500 mb-5 disabled:bg-green-300"
            v-if="userId != 'NEW_USER'" @click="update()" :disabled="pending">
                {{$t("Update user")}}
        </button>
    </div>
    <UserOrganizationDomainRoleEdit :user-id="userId"/>
</template>

<script>
import UsersService from '@ivy/users-service'
import AuthService from '@ivy/auth-service'

import UserOrganizationDomainRoleEdit from './UserOrganizationDomainRoleEdit.vue'

export default {
    name: 'UserEdit',
    components: {
        UserOrganizationDomainRoleEdit
    },
    props: {
        'userId': String,
    },
    emits: [
        'new-user'
    ],
    data() {
        return {
            fixedFirstname: '',
            fixedLastname: '',

            username: '',
            password: '',
            firstname: '',
            lastname: '',
            email: '',
            pending: false,
            isAdmin: AuthService.isAdmin()
        }
    },
    mounted() {
        var _self = this;
        if(this.userId != 'NEW_USER') {
            console.log("GET USER WITH ID ", this.userId);
            _self.pending = true;
            UsersService.get(this.userId)
            .then(function(response) {
                let user = response.data;
                _self.fixedFirstname = user.firstname;
                _self.fixedLastname = user.lastname;

                _self.username = user.username;
                _self.password = user.password;
                _self.firstname = user.firstname;
                _self.lastname = user.lastname;
                _self.email = user.email;
            })
            .catch(error => console.error(error))
            .finally(() => _self.pending = false);
        }
    },
    methods: {
        goBack() {
            this.$emit('backToList');
        },
        create() {
            this.pending = true
            UsersService.create({
                username: this.username,
                password: this.password,
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email
            }).then(() => {
                this.$emit('backToList');
            })
            .finally(() => this.pending = false)
        },
        update() {
            this.pending = true
            UsersService.update(this.userId, {
                username: this.username,
                // password: this.password,
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email
            }).then(() => {
                this.$emit('backToList');
            })
            .finally(() => this.pending = false)
        }
    }
}
</script>