<template>
  <div>
    <!-- <UserList v-if="!this.userId" :users="this.users" :newUser="newUser"></UserList> -->
    <UserList v-if="!this.userId" :users="this.users" @editUser="editUser"></UserList>
    <UserEdit v-if="this.userId" :user-id="this.userId" @backToList="backToList" @new-user="addUser"></UserEdit>
  </div>
</template>

<script>
import UserList from './components/UsersList.vue'
import UserEdit from './components/UserEdit.vue'

import UsersService from '@ivy/users-service'
import RouterService from '@ivy/router-service'

export default {
  name: 'AdminUsers',
  components: {
    UserList,
    UserEdit
  },
  data() {
    return {
      users: [],
      newUser: null,
      userId: null,
    }
  },
  mounted() {
    this.initUserAdmin();
  },
  methods: {
    initUserAdmin() {
      this.userId = RouterService.getParams('userId') ?? null;
      console.log(this.userId);
      if(!this.userId) {
        UsersService.list().then(response => {
          this.users = response.data
        })
      } else if(this.userId != 'NEW_USER') {
        // Get user detail
      }
    },
    backToList() {
      RouterService.goTo('ADMIN_USERS')
      this.initUserAdmin();
    },
    addUser(user) {
      this.newUser = user
    },
    editUser(userId) {
      RouterService.goTo('ADMIN_USERS', {userId: userId})
      this.initUserAdmin();
    },
  }
}
</script>

<style></style>
