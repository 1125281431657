<template>
  <h1 class="mb-4 text-4xl font-bold leading-none tracking-tight text-custom_organization-900">{{$t("Users")}}</h1>

  <div class="flex justify-end">
    <button class="flex gap-2 rounded-full py-2 px-3 min-w-[100px] bg-green-400 hover:bg-green-500 mb-5"
      @click="addUser()">
      <i class="gg-add-r"></i>{{$t("Add a user")}}
    </button>
  </div>
    <table class="table-fixed min-w-full rounded-xl">
      <thead class="bg-custom_organization-200">
        <tr>
          <th class="py-2">{{$t("Identifier")}}</th>
          <th class="py-2">{{$t("Login")}}</th>
          <th class="py-2">{{$t("Firstname")}}</th>
          <th class="py-2">{{$t("Lastname")}}</th>
          <th class="py-2">{{$t("Email")}}</th>
          <th class="py-2">{{$t("Actions")}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in usersToDisplay" v-bind:key="user.id" class="border-solid border-custom_organization-200 border-b-2 text-center">
          <td class="py-2">{{ user.id }}</td>
          <td class="py-2">{{ user.username }}</td>
          <td class="py-2">{{ user.firstname }}</td>
          <td class="py-2">{{ user.lastname }}</td>
          <td class="py-2">{{ user.email }}</td>
          <td class="flex gap-4 justify-end px-3">
            <button class="rounded-full h-8 w-8 flex justify-center items-center group" @click="editUser(user.id)">
              <i class="gg-pen cursor-pointer group-hover:text-custom_organization-700"></i>
            </button>
            <!-- <i class="gg-trash"></i> -->
            <!-- <td class="py-2"><button :disabled="pendingUserId.includes(user.id) || user.id == 1" @click="remove(user.id)">Delete</button></td> -->
          </td>
        </tr>
      </tbody>
    </table>
  </template>

<script>
import UsersService from '@ivy/users-service'

export default {
  name: 'UsersList',
  components: {

  },
  props: {
    'users': Array,
  },
  data() {
    return {
      pendingUserId: [],
      removedUserIds: []
    }
  },
  methods: {
    addUser() {
      this.editUser('NEW_USER');
    },
    editUser(userId) {
      this.$emit('editUser', userId);
    },
    remove(userId) {
      this.pendingUserId.push(userId)
      UsersService.delete(userId)
        .then(() => {
          this.removedUserIds.push(userId)
        })
        .finally(() => this.pendingUserId = this.pendingUserId.filter(id => id != userId))
    }
  },
  computed: {
    usersToDisplay() {
      return this.users.filter(user => !this.removedUserIds.includes(user.id))
    }
  }
}
</script>