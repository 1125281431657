<template>
    <hr class="my-10" />
    <div class="flex justify-between">
        <div class="flex flex-col">
            <div class="flex gap-6 items-center">
                <label class="w-32">{{$t("Organization")}} :</label>
                <input class="col-span-9 block bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-custom_organization-500 focus:ring-custom_organization-500 focus:border-custom_organization-500 w-60 p-2.5"
                    v-model="organization"/>
            </div>
            <div class="flex gap-6 items-center">
                <label class="w-32">{{$t("Domain")}} :</label>
                <input class="col-span-9 block bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-custom_organization-500 focus:ring-custom_organization-500 focus:border-custom_organization-500 w-60 p-2.5"
                    v-model="domain"/>
            </div>
            <div class="flex gap-6 items-center">
                <div class="w-32">{{$t("Role")}} :</div>
                <input class="col-span-9 block bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-custom_organization-500 focus:ring-custom_organization-500 focus:border-custom_organization-500 w-60 p-2.5"
                    v-model="role"/>
            </div>
        </div>
        <div class="flex flex-col gap-1">
            <span>{{$t("Roles")}} :</span>
            <ul>
                <li v-for="role in roles" v-bind:key="role.id">
                    - {{role.name}}
                </li>
            </ul>
        </div>
        <div class="flex flex-col gap-1">
            <span>{{$t("Groups")}} :</span>
            <ul>
                <li v-for="group in groups" v-bind:key="group.id">
                    - {{group.name}}
                </li>
            </ul>
        </div>

    </div>
    <div class="flex gap-2 mt-10">
        <button class="rounded-full py-2 px-3 min-w-[100px] bg-green-400 hover:bg-green-500 mb-5 disabled:bg-green-300"
            @click="addRole">{{$t("Add role")}}</button>
        <button class="rounded-full py-2 px-3 min-w-[100px] bg-red-400 hover:bg-red-500 mb-5 disabled:bg-red-300"
            @click="removeRole">{{$t("Remove role")}}</button>
    </div>
</template>

<script>
import UsersService from '@ivy/users-service'
// import AuthService from '@ivy/auth-service'

export default {
    name: 'UserEdit',
    components: {
    },
    props: {
        'userId': String,
    },
    data() {
        return {
            organization: '',
            domain: '',
            role: '',
            roles: [],
            groups: [],
        }
    },
    mounted() {
        // if(this.userId != 'NEW_USER') {
        //     let jwt = AuthService.getJwt();
        //     console.log(jwt);
        // }
        this.getUserRoles();
        this.getUserGroups();
    },
    methods: {
        async addRole() {
            await UsersService.addUserOrganizationDomainRole(
                this.userId,
                this.organization,
                this.domain,
                this.role
            );
            this.getUserRoles();
        },
        async removeRole() {
            await UsersService.removeUserOrganizationDomainRole(
                this.userId,
                this.organization,
                this.domain,
                this.role
            );
            this.getUserRoles();
        },
        async getUserRoles() {
            var result = await UsersService.getUserRoles(this.userId);
            this.roles = result.data;
        },
        async getUserGroups() {
            var result = await UsersService.getUserGroups(this.userId);
            this.groups = result.data;
        }
    }
}
</script>